import React from 'react';
import './CardV2.scss';

export function CardV2({ label, children, className, onClick }) {
  return (
    <div
      onClick={onClick}
      className={
        'info-card card card-v2 p-3 d-flex align-items-center justify-content-center h-100 ' +
        className
      }
    >
      <div className="d-flex flex-column text-center justify-content-between h-100">
        {children}
        {label && (
          <h4 style={{ fontSize: '14px' }} className="mt-3">
            {label}
          </h4>
        )}
      </div>
    </div>
  );
}
