/* eslint-disable no-undef */
import { transactionServices } from '@elrondnetwork/dapp-core';

import {
  ProxyProvider,
  SmartContract,
  Address,
  ContractFunction,
  AddressValue,
  Balance,
  NetworkConfig,
} from '@elrondnetwork/erdjs';

import {
  contractAddress,
  elrondApiAddress,
  elrondNetGateway,
  gasLimit,
  ticketPrice,
} from '../config';
import {
  customRound,
  decimalToHex,
  hexToDecimal,
  parseHexResponse,
  parseRawResponse,
  strToHex,
} from './utis';

const { sendTransactions } = transactionServices;

// WalletInfo { tokens: BigUint, rewards: BigUint }
const walletInfoFields = [
  {
    type: 'BigUint',
  },
  {
    type: 'BigUint',
  },
];

const lotteryInfoFields = [
  {
    name: 'max_tickets',
    type: 'u32',
  },
  {
    name: 'max_tokens',
    type: 'BigUint',
  },
  {
    name: 'sold_tickets',
    type: 'u32',
  },
  {
    name: 'sold_tokens',
    type: 'BigUint',
  },
  {
    name: 'sold_egld',
    type: 'BigUint',
  },
];

const ticketInfoFields = [
  {
    name: 'ticket_number',
    type: 'u32',
  },
  {
    name: 'num_tokens',
    type: 'BigUint',
  },
  {
    name: 'winning_tokens',
    type: 'BigUint',
  },
];

let provider;
let contract;

async function initApi() {
  contract = new SmartContract({
    address: new Address(contractAddress),
  });

  provider = new ProxyProvider(elrondNetGateway);
  await NetworkConfig.getDefault().sync(provider);
}

async function queryContractFunction(functionName, address, args = null) {
  if (!contract) {
    await initApi();
  }

  const options = {
    func: new ContractFunction(functionName),
  };

  if (address) {
    options.args = [new AddressValue(new Address(address))];
  }

  if (args) {
    options.args = [...options.args, ...args];
  }

  const response = await contract.runQuery(provider, options);
  return response;
}

export async function getTokenPrice() {
  const response = await queryContractFunction('token_price');

  return parseReturnData(response.returnData[0]);
}

export async function getCurrentAvailableTokens() {
  const response = await queryContractFunction('current_available_tokens');

  return parseReturnData(response.returnData[0]);
}

export async function getMaxTickets() {
  const response = await queryContractFunction('max_tickets');

  return parseReturnData(response.returnData[0]);
}

export async function getCurrentMaxTokens() {
  const response = await queryContractFunction('current_max_tokens');

  return parseReturnData(response.returnData[0]);
}

export async function getMinTicketsPerBuy() {
  const response = await queryContractFunction('min_tokens_per_buy');

  return parseReturnData(response.returnData[0]);
}

export async function getPreSalePhase() {
  const response = await queryContractFunction('presale_phase');

  if (responseIsEmpty(response)) {
    return 0;
  }

  return parseReturnData(response.returnData[0]);
}

export async function canClaim() {
  const response = await queryContractFunction('can_claim');

  if (response.returnCode.text !== 'ok') {
    return false;
  }

  return response.returnCode.text === 'ok';
}

export async function getRemainingTokensToBuy(address) {
  const response = await queryContractFunction(
    'remaining_tokens_to_buy',
    address
  );

  return parseReturnData(response.returnData[0]);
}

export async function getCanBuyTokens(address, payment) {
  const response = await queryContractFunction(
    'can_buy_tokens',
    address,
    payment
  );

  if (response.returnCode.text !== 'ok') {
    return Promise.reject(response.returnMessage);
  }

  return response.returnCode.text === 'ok';
}

export async function getMyTokens(address) {
  const response = await queryContractFunction('my_tokens', address);

  if (responseIsEmpty(response)) {
    return [0, 0];
  }

  const hexResponse = responseToHex(response.returnData[0]);
  return parseRawResponse(hexResponse, walletInfoFields);
}

export async function getMyTicketInfo(address) {
  const response = await queryContractFunction('my_ticket_info', address);

  if (response.returnCode.text !== 'ok') {
    return Promise.reject(response.returnMessage);
  }

  const hexResponse = responseToHex(response.returnData[0]);
  return parseHexResponse(hexResponse, ticketInfoFields);
}

export async function getMyLotteryTickets(address) {
  const response = await queryContractFunction('my_tickets', address);

  if (responseIsEmpty(response)) {
    return null;
  }

  return parseReturnData(response.returnData[0]);
}

export async function getCanBuyLotteryTicket(address, payment) {
  const response = await queryContractFunction(
    'can_buy_lottery_ticket',
    address,
    payment
  );

  if (response.returnCode.text !== 'ok') {
    return Promise.reject(response.returnMessage);
  }

  return response.returnCode.text === 'ok';
}

export async function getCanBuyFairTicket(address) {
  const response = await queryContractFunction('can_buy', address);

  if (response.returnCode.text !== 'ok') {
    return Promise.reject(response.returnMessage);
  }

  return response.returnCode.text === 'ok';
}

export async function buyTokens(balance) {
  const transaction = {
    receiver: contractAddress,
    data: 'buy_tokens',
    value: Balance.egld(balance),
    gasLimit: gasLimit,
  };

  const { sessionId, error } = await sendTransactions({
    transactions: [transaction],
  });

  if (error !== undefined) {
    Promise.reject(error);
  }

  return new Promise((resolve) => {
    resolve(sessionId);
  });
}

export async function buyLotteryTicket(balance) {
  const transaction = {
    receiver: contractAddress,
    data: 'buy_lottery_ticket',
    value: Balance.egld(balance),
    gasLimit: gasLimit,
    signWithoutSending: false,
  };

  const { sessionId, error } = await sendTransactions({
    transactions: [transaction],
  });

  if (error !== undefined) {
    Promise.reject(error);
  }

  return new Promise((resolve) => {
    resolve(sessionId);
  });
}

export async function stakeNFT(nft, address) {
  const transaction = {
    receiver: new Address(address).hex(),
    data: `ESDTNFTTransfer@${strToHex(nft.collection)}@${decimalToHex(
      nft.nonce
    )}@01@${new Address(contractAddress).hex()}@${strToHex('stake_nft')}`,
    value: '0',
    gasLimit: gasLimit,
    signWithoutSending: false,
  };

  const { sessionId, error } = await sendTransactions({
    transactions: [transaction],
  });

  if (error !== undefined) {
    Promise.reject(error);
  }

  return new Promise((resolve) => {
    resolve(sessionId);
  });
}

export async function unstakeNFTs() {
  const transaction = {
    receiver: new Address(contractAddress).hex(),
    data: `unstake_all`,
    value: '0',
    gasLimit: gasLimit,
    signWithoutSending: false,
  };

  const { sessionId, error } = await sendTransactions({
    transactions: [transaction],
  });

  if (error !== undefined) {
    Promise.reject(error);
  }

  return new Promise((resolve) => {
    resolve(sessionId);
  });
}

export async function claimTokens() {
  const transaction = {
    receiver: new Address(contractAddress).hex(),
    data: `claim_tokens`,
    value: '0',
    gasLimit: gasLimit,
    signWithoutSending: false,
  };

  const { sessionId, error } = await sendTransactions({
    transactions: [transaction],
  });

  if (error !== undefined) {
    Promise.reject(error);
  }

  return new Promise((resolve) => {
    resolve(sessionId);
  });
}

export async function getStakedNfts(address) {
  const response = await queryContractFunction('MyStakedNFTs', address);

  if (responseIsEmpty(response)) {
    return 0;
  }

  const hexResponse = responseToHex(response.returnData[0]);
  const parsed = parseHexResponse(
    hexResponse,
    [
      {
        name: 'token_identifier',
        type: 'TokenIdentifier',
      },
      {
        name: 'nonce',
        type: 'u64',
      },
    ],
    true
  );

  const nfts = {};
  for (let nft of parsed) {
    nfts[nft.token_identifier] = {
      nonce: nft.nonce,
      thumbnail: await fetch(
        elrondApiAddress +
          `nfts/${nft.token_identifier}-${decimalToHex(nft.nonce)}`
      )
        .then((res) => res.json())
        .then((res) => res.media[0].thumbnailUrl),
    };
  }
  return nfts;
}

export async function getCanStake(address) {
  const response = await queryContractFunction('can_stake', address);

  if (response.returnCode.text !== 'ok') {
    return false;
  }

  return parseReturnData(response.returnData[0]) === 1;
}

export async function getCanUnstake(address) {
  const response = await queryContractFunction('can_unstake', address);

  if (response.returnCode.text !== 'ok') {
    return false;
  }

  return parseReturnData(response.returnData[0]) === 1;
}

export async function getLotteryInfo() {
  const response = await queryContractFunction('get_lottery_info');

  if (responseIsEmpty(response)) {
    return 0;
  }

  const hexResponse = responseToHex(response.returnData[0]);
  return parseHexResponse(hexResponse, lotteryInfoFields);
}

export async function getPresaleSummaryInfo() {
  const response = await queryContractFunction('PresaleSummaryInfo');

  if (responseIsEmpty(response)) {
    return 0;
  }

  const hexResponse = responseToHex(response.returnData[0]);
  const parsed = parseHexResponse(hexResponse, [
    {
      name: 'max_tickets',
      type: 'u32',
    },
    {
      name: 'sold_tickets',
      type: 'u32',
    },
    {
      name: 'sold_tokens',
      type: 'BigUint',
    },
    {
      name: 'sold_egld',
      type: 'BigUint',
    },
  ]);

  parsed.sold_tokens = customRound(Number(parsed.sold_tokens) / 10 ** 18, 0);
  parsed.sold_egld = customRound(Number(parsed.sold_egld) / 10 ** 18);
  return parsed;
}

export async function getMyTotalFairTickets(address) {
  const response = await queryContractFunction('MyTotalFairTickets', address);

  if (responseIsEmpty(response)) {
    return 0;
  }

  return parseReturnData(response.returnData[0]);
}

export async function buyFairTickets(amount, discount) {
  const bigAmount = BigInt(amount);
  const bigPrice = BigInt(ticketPrice * 10 ** 18);
  const bigDiscount = BigInt(discount * 10 ** 18);

  const transaction = {
    receiver: contractAddress,
    data: `buy_fair_tickets@${decimalToHex(amount)}`,
    value: Balance.egld(
      Number((bigAmount * bigPrice * bigDiscount) / BigInt(10 ** 18)) / 10 ** 18
    ),
    gasLimit: gasLimit * amount,
  };

  const { sessionId, error } = await sendTransactions({
    transactions: [transaction],
  });

  if (error !== undefined) {
    Promise.reject(error);
  }

  return new Promise((resolve) => {
    resolve(sessionId);
  });
}

function parseReturnData(response) {
  const hexResponse = Buffer.from(response, 'base64').toString('hex');
  return hexToDecimal(hexResponse);
}

export function responseToHex(res) {
  return Buffer.from(res, 'base64').toString('hex');
}

function responseIsEmpty(response) {
  return response.returnData.length === 0 || response.returnData[0] === '';
}
