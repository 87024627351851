export const contractAddress =
  'erd1qqqqqqqqqqqqqpgql2kq9zs09y6vget39y4dhnm98hf6m53jznyqfv2xz4';
export const dAppName = 'ProteoPresale';
export const elrondApiAddress = 'https://api.elrond.com/';
export const elrondNetGateway = 'https://gateway.elrond.com';
export const environment = 'mainnet'; // 'testnet' | 'devnet' | 'mainnet'
export const Token = 'PROTEO-0c7311';
export const gasLimit = 10000000;

export const proteoPrice = 0.02975;
export const ticketPrice = 2.975;
export const maxBuySafeAmount = 0.05;

export const decimals = 4;
export const nftCollections = [
  'SRC-27d8ff',
  'BONPACKS-f0b549',
  'GIANTS-93cadd',
  'KROGAN-54c361',
  'PITTZ-1a4c2d',
];

export const nftCollectionNames = {
  'SRC-27d8ff': 'Space Robots',
  'BONPACKS-f0b549': 'Battle Of Nodes',
  'GIANTS-93cadd': 'Giants',
  'KROGAN-54c361': 'Krogan',
  'PITTZ-1a4c2d': 'Crypto Pittz',
};
