import { Container, Row, Col, Button, Card, Image } from 'react-bootstrap';
import './Presale2.scss';
import Presale2Image from '../Assets/img/presale2.png';
import Swap from '../Components/Swap';

import { useNavigate } from 'react-router-dom';
import Navbar from '../Views/Nav';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core';
import { useEffect, useState } from 'react';
import {
  canClaim,
  claimTokens,
  getCurrentAvailableTokens,
  getMyTokens,
  getPreSalePhase,
} from '../Services/api';
import { proteoPrice } from '../config';
import { customDenominate, customRound, hexToDecimal } from '../Services/utis';

export function Presale22() {
  const navigate = useNavigate();
  const { account, address } = useGetAccountInfo();
  const [availableTokens, setAvailableTokens] = useState(0);
  const [phase, setPhase] = useState(null);
  const [state, _setState] = useState({
    modalOpened: false,
    info: {},
    tokens: 0,
  });

  const setState = (newState) => {
    _setState((state) => ({ ...state, ...newState }));
  };

  useEffect(() => {
    getMyTokens(address).then((res) => setState({ tokens: res }));
    getCurrentAvailableTokens().then((res) => {
      setAvailableTokens(res);
    });
    getPreSalePhase().then((res) => setPhase(res));
    canClaim().then((res) => setState({ canClaim: res }));
  }, [address, account.balance]);

  return (
    <>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <Container className="presale-2">
        <Row>
          <Col className="text-right">
            <Navbar />
          </Col>
        </Row>

        <Row>
          <Col
            className="d-md-none d-flex justify-content-center"
            xs={12}
            style={{ height: '80px', overflow: 'hidden' }}
          >
            <div>
              <Image src={Presale2Image}></Image>
            </div>
          </Col>
          <Col className="d-none d-md-block" md={4}>
            <Image className="px-5" src={Presale2Image}></Image>
          </Col>
          <Col>
            <Container className="p-5">
              <Row>
                <Col className="pt-5 gold">
                  {phase === 2
                    ? 'Public sale opened for everybody'
                    : 'Presale v2.1'}
                </Col>
              </Row>
              <Row className="mt-3">
                {phase === 2 && (
                  <Col>
                    <p style={{ fontSize: '22px' }}>
                      You can buy without limit
                    </p>
                    <p style={{ fontSize: '22px' }}>First come first serve</p>

                    <Button
                      className="btn-presale mt-4"
                      style={{ width: '300px' }}
                      disabled={!address || availableTokens <= 0}
                      onClick={() => setState({ modalOpened: true })}
                    >
                      BUY PROTEO
                    </Button>
                  </Col>
                )}
                {phase !== 2 && (
                  <Col>
                    <p>Presale Sold Out</p>
                    <p>
                      In some days our first version of the Dapp will be able to
                      Autostake your PROTEO
                    </p>
                    <p>Meanwhile, here can you see you investments</p>
                  </Col>
                )}
              </Row>
            </Container>

            {phase === 2 && (
              <Row className="mt-5 mb-4 px-5">
                <Col className="text-center">
                  <h2>Available tokens</h2>
                  <h3 className="mt-3">
                    {customRound(customDenominate(availableTokens), 0)} $PROTEO
                  </h3>
                </Col>
              </Row>
            )}

            <Row className="mt-5 mb-4 px-5">
              <Col className="text-center">
                <h2>Your control panel</h2>
              </Col>
            </Row>
            <Row className="mb-4 px-5">
              <Col>
                <Card>
                  <p className="gold mb-2">
                    {customRound(
                      customDenominate(hexToDecimal(state.tokens[0] || '0'))
                    )}
                  </p>
                  <p>My balance in PROTEO</p>
                </Card>
              </Col>
              <Col>
                <Card>
                  <p className="gold mb-2">
                    {customRound(
                      customDenominate(hexToDecimal(state.tokens[1] || '0'))
                    )}
                  </p>
                  <p>My Rewards (25% APR)</p>
                </Card>
              </Col>
            </Row>
            <Row className="pb-2 px-5">
              <Col className="d-flex justify-content-center">
                <Button
                  disabled={!address || !state.canClaim || state.tokens[0] == 0}
                  className="btn-presale"
                  onClick={() => claimTokens()}
                >
                  CLAIM YOUR PROTEO
                </Button>
              </Col>
            </Row>
            <Row className="mt-5 mb-5 pb-5 px-5">
              <Col className="d-flex justify-content-center">
                <Button
                  className="btn-presale"
                  onClick={() => navigate('/nft')}
                >
                  SEND ME TO THE STAKING NFT PAGE
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {state.modalOpened && (
        <Swap
          tokenPrice={proteoPrice}
          setModal={() => setState({ modalOpened: false })}
          myTokens={state.tokens}
        ></Swap>
      )}
    </>
  );
}
