import { toast } from 'react-toastify';
import { decimals } from '../config';

const TOAST_OPTIONS = {
  position: 'bottom-right',
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
};

export function notifyError(message) {
  toast.error(message, TOAST_OPTIONS);
}

export function hexToString(hex) {
  var str = '';

  for (var i = 0; i < hex.length; i += 2) {
    var v = parseInt(hex.substr(i, 2), 16);
    if (v) str += String.fromCharCode(v);
  }
  return str;
}

export function strToHex(str) {
  return new Buffer(str).toString('hex');
}

export function hexToDecimal(hexString) {
  return parseInt(hexString, 16);
}

export function decimalToHex(number) {
  let hex = number.toString(16);
  if (hex.length % 2 === 1) hex = '0' + hex;
  return hex;
}

/* Parses raw response in HEX.
 *
 * @param rawResponse
 * @param fields
 * @returns Array with the fields in HEX.
 */
export function parseRawResponse(rawResponse, fields) {
  let raw = rawResponse;
  return fields.map((field) => {
    let dataField;
    // eslint-disable-next-line default-case
    switch (field.type) {
      case 'u32':
        dataField = raw.substring(0, 8);
        raw = raw.slice(8);
        break;
      case 'u16':
        dataField = raw.substring(0, 4);
        raw = raw.slice(4);
        break;
      case 'BigUint':
        const sizeBytes = parseInt(raw.substring(0, 8), 16);
        const end = 8 + sizeBytes * 2;
        dataField = raw.substring(8, end);
        raw = raw.slice(end);
        break;
      case 'BoxedBytes':
        dataField = this.parseBoxedBytes(raw);
        raw = '';
    }
    return dataField;
  });
}

/**
 * Parses BoxedBytes to array of string
 * @param rawHex
 * @returns array of string
 */
export function parseBoxedBytes(rawHex) {
  let raw = rawHex;
  const items = [];
  /**
   * rawHex = NNNNNNNNDDDDDDDDDDDDD
   * N: Size of data
   * D: Data
   *
   * e.g.
   * 00000007422d3530333138
   * N=00000007 (7 bytes)
   * D=422d3530333138 (7 bytes equals to 14 hex chars)
   */
  while (raw && raw.length > 0) {
    const sizeBytes = parseInt(raw.substring(0, 8), 16); // Get size
    const end = 8 + sizeBytes * 2;
    if (sizeBytes > 0) {
      const rawData = raw.slice(8, end);
      items.push(this.hexToString(rawData));
    }
    raw = raw.slice(end);
  }
  return items;
}

export function customDenominate(value) {
  if (typeof value === 'string') {
    return 0;
  }

  return value / 10 ** 18;
}

export function customRound(value, n = decimals) {
  if (value === 0) {
    return 0;
  }
  const d = 10 ** n;
  const rounded = Math.round((value + Number.EPSILON) * d) / d;
  return rounded.toLocaleString('en-US', {
    minimumFractionDigits: n,
  });
}

export function parseHexResponse(hexResponse, fields, isArray) {
  let raw = hexResponse;
  const res = [];

  let count = 1;
  if (isArray) {
    count = parseInt(raw.substring(0, 8), 16);
    raw = raw.slice(8);
  }

  for (let i = 0; i < count; i++) {
    res.push([]);

    for (let field of fields) {
      let dataField = null;
      // eslint-disable-next-line default-case
      switch (field.type) {
        case 'bool':
          dataField = Boolean(hexToDecimal(raw.substring(0, 2)));
          raw = raw.slice(2);
          break;
        case 'u32':
          dataField = hexToDecimal(raw.substring(0, 8));
          raw = raw.slice(8);
          break;
        case 'u64':
          dataField = hexToDecimal(raw.substring(0, 16));
          raw = raw.slice(16);
          break;
        case 'TokenIdentifier':
          const tokenSize = parseInt(raw.substring(0, 8), 16);
          const tokenEnd = 8 + tokenSize * 2;
          // eslint-disable-next-line no-undef
          dataField = hexToString(raw.substring(8, tokenEnd));
          raw = raw.slice(tokenEnd);
          break;
        case 'BigUint':
          const sizeBytes = parseInt(raw.substring(0, 8), 16);
          const end = 8 + sizeBytes * 2;
          // eslint-disable-next-line no-undef
          dataField = BigInt('0x' + (raw.substring(8, end) || '00'));
          raw = raw.slice(end);
          break;
      }

      res[i][field.name] = dataField;
    }
  }

  return isArray ? res : res[0];
}

export function denominateBigInt(value) {
  return Number(value) / 10 ** 18;
}
