import {
  transactionServices,
  useGetAccountInfo,
} from '@elrondnetwork/dapp-core';
import { useEffect, useState, useRef } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { CardV2 } from '../Components/CardV2';
import {
  elrondApiAddress,
  nftCollectionNames,
  nftCollections,
} from '../config';
import Navbar from '../Views/Nav';
import { Animated } from 'react-animated-css';
import {
  getCanStake,
  getCanUnstake,
  getStakedNfts,
  stakeNFT,
  unstakeNFTs,
} from '../Services/api';
import { useNavigate } from 'react-router-dom';

export function NFT() {
  const myRef = useRef(null);
  const navigate = useNavigate();
  const { address, account } = useGetAccountInfo();
  const [stackedNfts, setStackedNfts] = useState({});
  const [nfts, setNfts] = useState([]);
  const [canStake, setCanStake] = useState(false);
  const [canUnstake, setCanUnstake] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(null);

  const [sessionId, setSessionId] = useState(undefined);
  transactionServices.useTrackTransactionStatus({
    transactionId: sessionId,
    onSuccess: () => {
      setSessionId(undefined);
    },
    onFail: () => {
      setSessionId(undefined);
    },
    onCancelled: () => {
      setSessionId(undefined);
    },
    onCompleted: () => {
      setSessionId(undefined);
    },
    onTimedOut: () => {
      setSessionId(undefined);
    },
  });

  useEffect(() => {
    fetch(
      `${elrondApiAddress}accounts/${address}/nfts?collections=${nftCollections.join()}&size=200`
    ).then(async (res) => {
      if (res.ok) {
        setNfts(await res.json());
      }
    });

    getStakedNfts(address).then((res) => {
      setStackedNfts(res);
    });

    getCanStake(address).then((res) => {
      setCanStake(res);
    });

    getCanUnstake(address).then((res) => {
      setCanUnstake(res);
    });
  }, [address, account.balance]);

  useEffect(() => {
    if (selectedCollection && window.innerWidth < 768) {
      myRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [selectedCollection]);

  return (
    <>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>

      <Container className="presale-2">
        <Col className="text-right">
          <Navbar />
        </Col>
        <section
          data-bs-version="5.1"
          className=" cid-sXr0b5OERy"
          id="article1-5"
          style={{
            marginTop: '0',
            paddingTop: '2rem',
            paddingBottom: '0rem',
            background: 'none',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="wrapper col-12 col-md-12">
                <h3 className="mbr-section-title mbr-fonts-style display-1">
                  NFT Staking
                </h3>
                <h6 className="text-center text-light mt-3">
                  Stake up to 5 NFts.
                  <p>5% discount with each NFT.</p>
                </h6>
              </div>
            </div>

            <div className="row">
              <Col
                md={{
                  span: 6,
                  offset: 3,
                }}
                className="p-0 text-center mt-2"
              >
                <Button className="btn-presale" onClick={() => navigate('/')}>
                  BACK TO BUY PAGE
                </Button>
              </Col>
            </div>
          </div>
        </section>

        <Row className="info-cards">
          <Col md={1} xs={0}></Col>
          {nftCollections.map((collection) => (
            <Col md={2} xs={6} key={collection} className="mt-4 mt-md-0">
              <CardV2
                onClick={() => {
                  if (!canStake || stackedNfts[collection]) {
                    return;
                  }
                  setSelectedCollection(collection);
                }}
                className={selectedCollection === collection ? 'selected' : ''}
                label={nftCollectionNames[collection]}
              >
                {stackedNfts[collection] ? (
                  <div>
                    <img
                      style={{ borderRadius: '4px' }}
                      src={stackedNfts[collection].thumbnail}
                      alt="nft"
                    />
                  </div>
                ) : (
                  <div className="d-flex align-items-center h-100">
                    <p className="text-primary">
                      {canStake ? 'Click to stake' : 'Staking is not allowed'}
                    </p>
                  </div>
                )}
              </CardV2>
            </Col>
          ))}
        </Row>
        {canUnstake && (
          <div className="row">
            <Col
              md={{
                span: 6,
                offset: 3,
              }}
              className="p-0 text-center mt-4"
            >
              <Button className="btn-presale" onClick={() => unstakeNFTs()}>
                UNSTAKE YOUR NFTS
              </Button>
            </Col>
          </div>
        )}
      </Container>

      <div ref={myRef}></div>
      <div className="m-5 px-4">
        {nftCollections
          .filter((collection) => selectedCollection === collection)
          .map((collection) => (
            <div key={collection} className="px-md-5 mx-md-5 mb-md-5">
              {Boolean(
                nfts.filter((nft) => nft.collection === collection).length
              ) ? (
                <h5 className="mb-0">
                  Select your {nftCollectionNames[collection]} to stake
                </h5>
              ) : (
                <h5 className="mb-0 text-center">
                  You don't have any NFT of this collection
                </h5>
              )}
              <Row className=" info-cards">
                {nfts
                  .filter((nft) => nft.collection === collection)
                  .map((nft, i) => (
                    <Col
                      xs={6}
                      md={4}
                      xl={2}
                      key={nft.identifier}
                      style={{ marginTop: '1.3rem' }}
                    >
                      <Animated
                        className="h-100"
                        animationIn="fadeInUp"
                        animationOut="fadeOut"
                        animationInDelay={i * 50}
                        animationInDuration={300}
                        isVisible={true}
                      >
                        <CardV2
                          onClick={async () => {
                            if (!sessionId) {
                              setSessionId(await stakeNFT(nft, address));
                            }
                          }}
                        >
                          <div className="d-flex flex-column justify-space-between">
                            <img src={nft.media[0].thumbnailUrl} alt="nft" />
                            <p
                              style={{ fontSize: '14px' }}
                              className="m-0 mt-3 text-primary"
                            >
                              {nft.identifier}
                            </p>
                          </div>
                        </CardV2>
                      </Animated>
                    </Col>
                  ))}
              </Row>
            </div>
          ))}
      </div>
    </>
  );
}
