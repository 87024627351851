import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DappProvider } from '@elrondnetwork/dapp-core';
import { environment } from './config';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <React.StrictMode>
    <DappProvider
      environment={environment}
      customNetworkConfig={{ name: 'customConfig', apiTimeout: 6000 }}
      completedTransactionsDelay={200}
    >
      <App />
    </DappProvider>
    <ToastContainer />
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
