import React, { useState } from 'react';
import EGLD from '../Assets/img/egld.png';
import { useGetAccountInfo, DappUI } from '@elrondnetwork/dapp-core';
import { buyTokens, getCanBuyTokens } from '../Services/api';
import { BigUIntValue } from '@elrondnetwork/erdjs/out';
import to from 'await-to-js';
import {
  customDenominate,
  customRound,
  hexToDecimal,
  notifyError,
} from '../Services/utis';

import ProteoIcon from '../Assets/img/proteo-icon.png';
import { Button } from 'react-bootstrap';
import { maxBuySafeAmount } from '../config';

function Swap(props) {
  const { setModal, tokenPrice, myTokens } = props;
  const [tokens, setTokens] = useState(0);
  const [eglds, setEglds] = useState(0);
  const { account } = useGetAccountInfo();

  const calculateTokens = (value) => {
    let final = value / tokenPrice;
    final = Math.round((final + Number.EPSILON) * 10000) / 10000;
    setTokens(final);
    setEglds(value);
  };

  const calculateEGLD = (value) => {
    let final = value * tokenPrice;
    final = Math.round((final + Number.EPSILON) * 10000) / 10000;
    setTokens(value);
    setEglds(final);
  };

  const checkIfUserCanBuy = async () => {
    setModal(false);
    const [err, canBuy] = await to(
      getCanBuyTokens(account.address, [new BigUIntValue(eglds * 10 ** 18)])
    );

    if (err) {
      notifyError(err);
      return;
    }

    if (canBuy) {
      const [err] = await to(buyTokens(eglds));

      if (err) {
        notifyError(err);
        return;
      }

      setModal(false);
    }
  };

  return (
    <>
      <div className="modal-overlay" />
      <div
        className="modal-wrapper-wallet swapModal"
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-wallet" style={{ background: 'rgb(0 0 0)' }}>
          <div className="modal-header-wallet unlock-page">
            <button
              onClick={() => setModal(false)}
              type="button"
              className="btn btn-light px-3 py-2"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="times"
                className="svg-inline--fa fa-times fa-w-10 fa-lg "
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
                ></path>
              </svg>
            </button>
            <div className="sc-eYKchh brndWq">
              <div className="sc-aiooD kTKBYk">
                <div className="sc-jSFjdj sc-gJjCVn sc-eKaNGd kJmatq kjXFBI cMApHi">
                  <div
                    fontSize="14px"
                    color="text"
                    className="sc-gtsrHT dUSdIO"
                    style={{ display: 'inline', cursor: 'pointer' }}
                  >
                    <DappUI.Denominate value={account.balance} />
                  </div>
                </div>
              </div>
              <div className="sc-jNjAJB csJSnX" style={{ paddingTop: 0 }}>
                <input
                  className="sc-cpUASM gDgFal token-amount-input"
                  onChange={(e) => calculateTokens(e.target.value)}
                  value={eglds}
                  type="number"
                  placeholder="0.0"
                  min="0"
                  spellCheck="false"
                />
                <button
                  className="sc-hKFxyN fiMYsI"
                  scale="sm"
                  onClick={() =>
                    calculateTokens(
                      account.balance / 10 ** 18 - maxBuySafeAmount
                    )
                  }
                >
                  MAX
                </button>
                <button
                  className="sc-hKFxyN fiMYsI sc-eSoXWK WzEhh open-currency-select-button"
                  scale="sm"
                >
                  <div className="sc-jSFjdj sc-gKAaRy kJmatq eqGhLO">
                    <img
                      className="sc-hxyAeK hLeXPM"
                      alt="USDT logo"
                      src={EGLD}
                      style={{ marginRight: 8 }}
                    />
                    <div id="pair" color="text" className="sc-gtsrHT hmxDlj">
                      EGLD
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div className="sc-eYKchh brndWq mt-4">
              <div className="sc-aiooD kTKBYk">
                <div className="sc-jSFjdj sc-gJjCVn sc-eKaNGd kJmatq kjXFBI cMApHi">
                  <div
                    fontSize="14px"
                    color="text"
                    className="sc-gtsrHT dUSdIO"
                    style={{ display: 'inline', cursor: 'pointer' }}
                  >
                    {myTokens &&
                      customRound(
                        customDenominate(hexToDecimal(myTokens[0] || '0'))
                      )}
                    <span className="ml-1">$PROTEO</span>
                  </div>
                </div>
              </div>
              <div className="sc-jNjAJB csJSnX">
                <input
                  className="sc-cpUASM gDgFal token-amount-input"
                  onChange={(e) => calculateEGLD(e.target.value)}
                  value={tokens}
                  type="number"
                  min="0"
                  spellCheck="false"
                ></input>
                <button
                  className="sc-hKFxyN fiMYsI"
                  scale="sm"
                  onClick={() =>
                    calculateTokens(
                      account.balance / 10 ** 18 - maxBuySafeAmount
                    )
                  }
                >
                  MAX
                </button>
                <button
                  className="sc-hKFxyN fiMYsI sc-eSoXWK WzEhh open-currency-select-button"
                  scale="sm"
                >
                  <div className="sc-jSFjdj sc-gKAaRy kJmatq eqGhLO">
                    <img
                      className="sc-hxyAeK hLeXPM"
                      alt="USDT logo"
                      src={ProteoIcon}
                      style={{ marginRight: 8 }}
                    />
                    <div id="pair" color="text" className="sc-gtsrHT hmxDlj">
                      PROTEO
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div className="mt-spacer px-3">
              <p className="text-white text-center mb-3">Exchange Rate</p>
              <p className="text-white text-center mb-3">
                1 EGLD ≃ {customRound(1 / tokenPrice, 4)} $PROTEO
              </p>
              <p className="text-white text-center mb-4">
                1 $PROTEO ≃ {customRound(tokenPrice)} EGLD
              </p>

              <div className="text-center">
                <Button
                  style={{ fontSize: '18px', fontWeight: 'bold' }}
                  className="btn btn-presale"
                  onClick={checkIfUserCanBuy}
                >
                  Buy Proteo
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Swap;
