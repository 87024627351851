import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Image,
  Modal,
  ModalBody,
  FormControl,
} from 'react-bootstrap';
import './Presale2.scss';
import Presale2Image from '../Assets/img/presale2.png';
import Capa4 from '../Assets/img/Capa4.png';
import Capa5 from '../Assets/img/Capa5.png';

import Capa6 from '../Assets/img/Capa6.png';

import Capa7 from '../Assets/img/Capa7.png';
import Capa8 from '../Assets/img/Capa8.png';
import N1 from '../Assets/img/numero1.png';
import N2 from '../Assets/img/numero2.png';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Views/Nav';
import { useGetAccountInfo, denominate } from '@elrondnetwork/dapp-core';
import { useEffect, useState } from 'react';
import {
  buyFairTickets,
  getMyTotalFairTickets,
  getPresaleSummaryInfo,
  getStakedNfts,
  getCurrentAvailableTokens,
  getCanBuyFairTicket,
} from '../Services/api';
import { ticketPrice } from '../config';
import { customDenominate, customRound } from '../Services/utis';

export function Presale2() {
  const navigate = useNavigate();
  const { account, address } = useGetAccountInfo();
  const [availableTokens, setAvailableTokens] = useState(0);
  const [state, _setState] = useState({
    modalOpened: false,
    info: {},
    tickets: 0,
    canBuyFairTicket: false,
  });

  const setState = (newState) => {
    _setState((state) => ({ ...state, ...newState }));
  };

  useEffect(() => {
    getPresaleSummaryInfo().then((res) => setState({ info: res }));
    getMyTotalFairTickets(address).then((res) => setState({ tickets: res }));
    getCanBuyFairTicket(address).then((res) =>
      setState({ canBuyFairTicket: res })
    );
    getCurrentAvailableTokens().then((res) => {
      setAvailableTokens(res);
    });
  }, [address, account.balance]);

  return (
    <>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <div className="particle"></div>
      <Container className="presale-2">
        <Row>
          <Col className="text-right">
            <Navbar />
          </Col>
        </Row>

        <Row>
          <Col
            className="d-md-none d-flex justify-content-center"
            xs={12}
            style={{ height: '80px', overflow: 'hidden' }}
          >
            <div>
              <Image src={Presale2Image}></Image>
            </div>
          </Col>
          <Col className="d-none d-md-block" md={4}>
            <Image className="px-5" src={Presale2Image}></Image>
          </Col>
          <Col>
            <Container>
              <Row>
                <Col md={1}></Col>
                <Col className="pt-5">
                  Please, read and follow this steps to make a smart purchase:
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="p-0 d-none d-md-block" md={1}>
                  <Image src={N1}></Image>
                </Col>
                <Col>
                  <p>
                    <span>OPTIONAL:</span> If you have any of our partner’s
                    NFTs, you must stake them
                  </p>
                  <p>
                    <span>before making your </span> purchase to obtain your
                    discount.
                  </p>
                  <p>
                    <span>Up to 25% discount:</span> 5% discount for each one of
                    these NFTs (only one per collection allowed)
                  </p>

                  <Row className="mt-4">
                    <Col>
                      <Image src={Capa4}></Image>
                    </Col>
                    <Col>
                      <Image src={Capa5}></Image>
                    </Col>
                    <Col>
                      <Image src={Capa6}></Image>
                    </Col>
                    <Col>
                      <Image src={Capa7}></Image>
                    </Col>
                    <Col>
                      <Image src={Capa8}></Image>
                    </Col>
                  </Row>

                  <p>
                    <span>Collections allowed:</span> GIANTS-93cadd //
                    PITTZ-1a4c2d // KROGAN-54c361 SRC-27d8ff (Main Space Robots)
                    // BONPACKS-f0b549 (Blue & Red Packs unopened)
                  </p>

                  <Col className="text-center mt-4 mb-4">
                    <Button
                      className="btn-presale"
                      onClick={() => navigate('/nft')}
                    >
                      SEND ME TO THE STAKING NFT PAGE
                    </Button>
                  </Col>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="p-0 d-none d-md-block" md={1}>
                  <Image src={N2}></Image>
                </Col>
                <Col>
                  <p>
                    If you have staked your NFTs or don’t have any to stake{' '}
                  </p>
                  <p>
                    <span>you are ready to buy PROTEO tickets </span>
                  </p>
                  <p>Remember: Up to 30 tickets of 100 PROTEO per ticket </p>
                  <p>
                    This phase open for whitelisted users from 31st of May to
                    3rd of June.
                  </p>
                  <Col className="text-center">
                    <Button
                      className="btn-presale mt-4"
                      disabled={
                        !state.canBuyFairTicket ||
                        !address ||
                        state.tickets >= state.info.max_tickets
                      }
                      onClick={() => setState({ modalOpened: true })}
                    >
                      BUY TICKET OF 100 PROTEO
                    </Button>
                  </Col>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row className="mt-5 mb-4 px-5">
          <Col className="text-center">
            <h2>Available tokens</h2>
            <h3 className="mt-3">
              {customRound(customDenominate(availableTokens))} $PROTEO
            </h3>
          </Col>
        </Row>
        <Row className="mt-2 pt-2 mb-2">
          <Col className="text-center">
            <h2>Your control panel</h2>
          </Col>
        </Row>
        <Row className="mb-5 pb-5">
          <Col xs={6} md={3} className="mt-4 mt-md-0">
            <Card>
              <p className="gold mb-2">
                {state.tickets}/{state.info.max_tickets || 0}
              </p>
              <p>My tickets</p>
            </Card>
          </Col>
          <Col xs={6} md={3} className="mt-4 mt-md-0">
            <Card>
              <p className="gold mb-2">{state.info.sold_tickets || 0}</p>
              <p>Sold tickets</p>
            </Card>
          </Col>
          <Col xs={6} md={3} className="mt-4 mt-md-0">
            <Card>
              <p className="gold mb-2">{state.info.sold_tokens || 0}</p>
              <p>Tokens sold in tickets</p>
            </Card>
          </Col>
          <Col xs={6} md={3} className="mt-4 mt-md-0">
            <Card>
              <p className="gold mb-2">{state.info.sold_egld || 0}</p>
              <p>Total EGLD sold</p>
            </Card>
          </Col>
        </Row>

        {address && account.balance !== '...' && (
          <BuyTicketModal state={state} setState={setState} />
        )}
      </Container>
    </>
  );
}

function BuyTicketModal({ state, setState }) {
  const [amount, _setAmount] = useState(1);
  const [stackedNFT, setStackedNfts] = useState(0);
  const [maxToBuy, setMaxToBuy] = useState(0);
  const { account, address } = useGetAccountInfo();

  useEffect(() => {
    getStakedNfts(address).then((res) => {
      setStackedNfts(Object.keys(res).length);
    });
  }, [address, account.balance]);

  useEffect(() => {
    if (!account?.balance) return;

    setMaxToBuy(
      Math.min(
        state.info.max_tickets - state.tickets,
        Math.floor(
          Number(denominate({ input: account.balance || 0 })) /
            (ticketPrice * (1 - (stackedNFT * 5) / 100))
        )
      )
    );
  }, [state.tickets, state.info.max_tickets, account.balance, stackedNFT]);

  const setAmount = (e) => {
    _setAmount(Math.min(Math.max(1, e), maxToBuy));
  };

  return (
    <Modal
      className="buy-tickets"
      show={state.modalOpened}
      onHide={() => {
        setState({ modalOpened: false });
      }}
    >
      <ModalBody>
        <Container className="text-center">
          <Row className="mb-4">
            <Col xs={12} md={6} className="mb-3">
              <p className="gold mb-2">Ticket Price</p>
              <p className="bold">{`${customRound(ticketPrice)} EGLD`}</p>
            </Col>
            <Col xs={12} md={6}>
              <p className="gold mb-2">Your Ticket Price</p>
              <p className="bold">
                {`${customRound(
                  ticketPrice * (1 - (stackedNFT * 5) / 100)
                )} EGLD`}{' '}
                (-
                {stackedNFT * 5}%)
              </p>
            </Col>
          </Row>
          <Row className="mb-4 stats">
            <Col>
              <p className="bold mb-1">Max Tickets</p>
              <p>{state.info.max_tickets}</p>
            </Col>
            <Col>
              <p className="bold mb-1">Bought Tickets</p>
              <p>{state.tickets}</p>
            </Col>
            <Col>
              <p className="bold mb-1">Pending Tickets</p>
              <p>{state.info.max_tickets - state.tickets}</p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="stats">
            <Col>
              Balance:{' '}
              {customRound(
                Number(denominate({ input: account.balance || '0' }))
              )}{' '}
              EGLD
            </Col>
          </Row>
          <Row>
            <Col xs={7} sm={8} md={6} className="pl-3 p-0">
              <FormControl
                value={amount}
                onChange={(el) => setAmount(el.target.value)}
                type="number"
              />
            </Col>
            <Col xs={5} sm={4} md={3} className="d-center">
              <Button
                className="circle mr-2"
                onClick={() => setAmount(amount - 1)}
              >
                -
              </Button>
              <Button className="circle" onClick={() => setAmount(amount + 1)}>
                +
              </Button>
            </Col>
            <Col xs={12} md={3} className="d-center p-0 pr-3 mt-2 mt-md-0">
              {customRound(
                amount * (ticketPrice * (1 - (stackedNFT * 5) / 100))
              )}{' '}
              EGLD
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-center">
              <Button
                className="btn-presale btn-small"
                onClick={() => {
                  setState({ modalOpened: false });
                  buyFairTickets(amount, 1 - (stackedNFT * 5) / 100);
                }}
              >
                Buy Tickets
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
}
