import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from 'react-router-dom';
import { DappUI } from '@elrondnetwork/dapp-core';
import { NFT } from './Pages/NFT';
import './global.scss';
import { Presale2 } from './Pages/Presale2';
import { Presale22 } from './Pages/Presale22';
import { useEffect, useState } from 'react';
import { getPreSalePhase } from './Services/api';

const {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal,
} = DappUI;

function App() {
  const [phase, setPhase] = useState(null);

  useEffect(() => {
    getPreSalePhase().then((res) => setPhase(res));
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path={'/'}
          element={
            phase === null ? (
              <></>
            ) : phase === 2 || phase === 4 ? (
              <Presale22 />
            ) : (
              <Presale2 />
            )
          }
        />
        <Route path={'/2'} element={<Presale22 />} />
        <Route path={'/nft'} element={<NFT />} />
        <Route path="/unlock" element={<Navigate to={'/'} />} />
      </Routes>
      <TransactionsToastList />
      <NotificationModal />
      <SignTransactionsModals />
    </Router>
  );
}

export default App;
